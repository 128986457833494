import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalVoidSearchComponent } from './../../modal-void-search/modal-void-search.component';
import {
  Component,
  HostListener,
  Input,
  OnInit,
  OnChanges,
  SimpleChange,
  SimpleChanges,
  ViewEncapsulation,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import {
  AerialSourceEnum,
  AirlineRules,
  Airport,
  ApiFlightType,
  MOMENT_FORMATS,
} from '@zupper/data';

import { ApiMSInsuranceService, StorageInsuranceService } from 'insurance-lib';

import {
  AirportsModel,
  AvailableFlightsRequest,
  AvailableFlightsResponse,
  DefaultResponseModel,
  SliceResponse,
} from '@zupper/aerial-components';
import {
  AirportInfoModel,
  ApiFilterModel,
  BaggageModel,
  FilterAirlinesModel,
  FilterDuration,
  FilterOptions,
  FilterTime,
} from '../../../models/api-filter.model';
import * as _ from 'lodash';
import { AerialSearchManagerService } from '../../../services/aerial-search-manager.service';
import {
  StopPriceFilter,
  StopPriceSelect,
  StopQtyPrice,
} from '../../../models/aerial-filters.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import moment from 'moment';
import { ResultFiltersParams } from '../../../models/result-params.model';
import { SubSink } from 'subsink';
import { DataLayerResultPageService } from '../../../services/data-layer-result-page.service';
import { COMBINED_SOURCES } from '@zupper/data';
import { tap, catchError } from 'rxjs/operators';
import { merge, Observable, throwError, of } from 'rxjs';
import { CustomerLoginService } from 'zupper-common';
import { MediaObserver } from '@angular/flex-layout';
import { FlightFilterStateService } from '../../../services/flight-filter-state.service';

@Component({
  selector: 'app-uncombined-result',
  templateUrl: './uncombined-result.component.html',
  styleUrls: ['./uncombined-result.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UncombinedResultComponent implements OnDestroy {
  @Input() ciasResponse: DefaultResponseModel[] = [];
  @Input() initialFilterValues: ResultFiltersParams;
  @Input() filterOptions: FilterOptions = new FilterOptions();
  @Input() flightsSearchFields: AvailableFlightsRequest = new AvailableFlightsRequest();

  filterMeta: ApiFilterModel[] = [];
  flightResponse: AvailableFlightsResponse[][] = [];
  flightList: AvailableFlightsResponse[][] = [];
  resultFiltered: AvailableFlightsResponse[][] = [];
  slicesList: SliceResponse[][][] = [];
  // chosenSliceList: SliceResponse[][] = [];
  chosenFlightList: AvailableFlightsResponse[][] = [];
  chosenAirlineRules: AirlineRules[] = [];
  // selectedFamilyName: Array<string> = [];
  selectedFlightList: Array<Array<string>> = [];
  isInternational: boolean = false;
  airlineStopsPrices: Array<StopPriceFilter> = [];
  multiAirlineStopsPrices: StopPriceFilter;
  airlines: Array<FilterAirlinesModel> = [];
  airports: Array<AirportInfoModel> = [];

  flightGroupsInitialShow: number = 5;
  flightGroupsTotal: number = 5;
  totalSlices: number = 0;
  maxStopsCount: number = 0;
  bestPricesByStops: Array<number> = [];
  stopQuantitiesList: Array<number> = [];
  minimalPrice: number = 0;
  maximumPrice: number = 0;
  timeDeparture: Array<FilterTime> = [];
  timeArrival: Array<FilterTime> = [];
  filterDuration: Array<FilterDuration> = [];
  showFamilies: boolean = false;
  isMultiCias: boolean = false;
  cias: string[] = [];
  tripType: string;

  protected _isInsuranceRequired: boolean = false;
  protected _requiredCountries: string[] = [];
  protected _airportCountries: string[] = [];
  private responseCount: number = 0;
  private subs = new SubSink();
  insuranceProducts: any;
  hasProductsInsurance: any;
  resultsInsurance: any = [];

  constructor(
    private _flightFilterStateService: FlightFilterStateService,
    private service: AerialSearchManagerService,
    private modalService: NgbModal,
    private router: Router,
    private ngxLoader: NgxUiLoaderService,
    private cdRef: ChangeDetectorRef,
    private dataLayerService: DataLayerResultPageService,
    private customerLoginService: CustomerLoginService,
    public mediaObserver: MediaObserver,
    protected _apiMSInsuranceService: ApiMSInsuranceService,
    private insuranceStorageService: StorageInsuranceService
  ) {
    this.subs.add(this._flightFilterStateService.selectedFlightList$.subscribe(data => {
      this.selectedFlightList = data;
    }));
  }

  ngOnInit(): void {

    this.ngxLoader.start();
    this.isInternational = JSON.parse(sessionStorage.getItem('isInternational'));
    this.tripType = sessionStorage.getItem('typeTrip');
    if (!this.isInternational) {
      this.showFamilies = true;
    }
    this._initResult();
    if (typeof sessionStorage.getItem("airport." + this.flightsSearchFields.slices[0].destinationAirport) !== "undefined")
      this._airportCountries.push(JSON.parse(sessionStorage.getItem("airport." + this.flightsSearchFields.slices[0].destinationAirport)).countries_iso);


  }

  private _initResult(): void {

    //this.getRequiredCountriesAndInsuranceProducts();


    this._buildCias();

    if (this.flightsSearchFields) {
      this._buildRequestFlightsSearchFields();
      this.totalSlices = this.flightsSearchFields.slices.length;
      this.flightResponse = [];
      const observables = [];

      const customerSessionId = this.customerLoginService.getCustomerSessionId();

      if (customerSessionId) {
        this.flightsSearchFields.customerSessionId = customerSessionId;
      }


      this.cias.forEach((cia: string) => {
        this.flightsSearchFields.airlines = [cia];
        let searchFieldSlice: AvailableFlightsRequest = { ...this.flightsSearchFields };
        if (this.checkMultiCias(cia)) {
          searchFieldSlice.grouped = true;
          searchFieldSlice.groupedByOptions = false;
        }
        observables.push(this._sendAvailableFlights(searchFieldSlice, cia));
      });

      merge(observables).subscribe({
        next: (res: Observable<any>) => {
          res.subscribe({
            next: (responseModel: DefaultResponseModel) => {
              if (Object.values(responseModel.result).length > 0) {
                this.resultsInsurance.push(responseModel);
              }

              this.responseCount++;

              if (this.responseCount == this.cias.length) {
                this.ngxLoader.stop();
                this.dataLayerService.load("Aerial", this.flightsSearchFields, this.airlineStopsPrices);
              }
              if (this.responseCount == this.cias.length) {
                this._getInsurerRequiredCountries();
              }
              if (this.responseCount == this.cias.length && this.flightList.length == 0) {
                this._openModalSearch();
              }
              this.cdRef.detectChanges();
            },
            error: (error) => {
              throw error(error);
            }
          });
        }, error: (error) => {
          throw error(error);
        }
      });
    }
  }

  private _buildFlightResponseInternational(data: DefaultResponseModel, cia: string): void {
    Object.keys(data.result).forEach((key) => {
      this.flightResponse[key] = data.result[key];
      if (!this.filterMeta[this.flightResponse[key][0].slices[0].source]) {
        this.filterMeta[this.flightResponse[key][0].slices[0].source] = data.meta;
      }
    });
  }

  private _buildRequestFlightsSearchFields(): void {
    this.flightsSearchFields.onlyBusinessClass = this.flightsSearchFields.onlyBusinessClass
      ? true
      : false;
    this.flightsSearchFields.onlyEconomicClass = this.flightsSearchFields.onlyBusinessClass
      ? false
      : true;
    if (this.flightsSearchFields.type == ApiFlightType.MultiSlice) {
      this.flightsSearchFields.type = ApiFlightType.RoundTrip;
    }

    this.flightsSearchFields.forceCacheRefresh = false;

    this.flightsSearchFields.grouped = this.isInternational;
    this.flightsSearchFields.groupedByOptions = !this.isInternational;
  }

  private _sendAvailableFlights(searchFieldSlice: AvailableFlightsRequest, cia: string): Observable<DefaultResponseModel> {
    return this.service.availableFlights(searchFieldSlice)
      .pipe(
        tap({
          next: (data: DefaultResponseModel) => {
            if (!_.isEmpty(data.result)) {
              this._buildResponse(data, cia);
              this.filterMeta[cia] = data.meta;

              this._buildFlightList(cia, data);
              this._buildFilterData({ ...this.filterMeta });
            }
          },
          error: () => {
            this.responseCount++;
            if (this.responseCount === this.cias.length) {
              this.ngxLoader.stop();
            }
          }
        })
      );
  }

  private _buildCias(): void {
    if (this.tripType != "oneWay") {
      this.cias.push(...COMBINED_SOURCES)
    }
    if (this.isInternational) {
      const startIndex = this.cias.indexOf(COMBINED_SOURCES[0]);
      this.cias.splice(startIndex, COMBINED_SOURCES.length);
    }

    Object.values(AerialSourceEnum).forEach((cia) => {
      this.cias.push(cia);
    });

    if (!this.isInternational) {
      this.cias = this.cias
        .filter((cia) => {
          return cia != 'Sabre';
        })
        .sort();
    }
  }

  private _buildResponse(data: DefaultResponseModel, cia: string): void {
    if (this.isInternational) {
      this._buildFlightResponseInternational(data, cia);
    } else {
      Object.keys(data.result).forEach((key) => {
        Object.values(data.result[key]).forEach((group) => {
          if (this.checkMultiCias(cia)) {
            this._buildGroupResponse(group, key, cia, data);
          } else {
            Object.values(group).forEach((flights) => {
              this._buildGroupResponse(flights, key, cia, data);
            });
          }
        });
      });
    }
  }

  private _buildFlightList(cia: string, data: DefaultResponseModel): void {
    this.flightList = [];

    Object.values(this.flightResponse).forEach((flights, index) => {
      this.flightList.push([...flights]);
    });
    this.flightList = this.flightList.sort((a, b) =>
      typeof a[0].totalPrice != 'undefined' && typeof b[0].totalPrice != 'undefined'
        ? a[0].totalPrice >= b[0].totalPrice
          ? 1
          : -1
        : 1
    );
    this.flightList.forEach((flights, index) => {
      this.flightsSearchFields.slices.forEach((slice, sliceIndex) => {
        let sortedFlights = flights.sort((f1, f2) => {
          if (f1.slices[sliceIndex].departureDate > f2.slices[sliceIndex].departureDate) {
              return 1;
          } else if (f1.slices[sliceIndex].departureDate < f2.slices[sliceIndex].departureDate) {
              return -1;
          } else {
              let duration1 = this._convertFlightDurationToMinutes(f1.slices[sliceIndex].flightDuration);
              let duration2 = this._convertFlightDurationToMinutes(f2.slices[sliceIndex].flightDuration);
              return duration1 - duration2;
          }
        });
        this._flightFilterStateService.saveChosenFlight(sortedFlights[0], index, sliceIndex);
      });
      this.getSliceList(flights, index);

      return flights;
    });
  }

  private _convertFlightDurationToMinutes(duration: string): number {
    let daysMatch = duration.match(/(\d+)D/);
    let hoursMatch = duration.match(/(\d+)H/);
    let minutesMatch = duration.match(/(\d+)M/);
    let days = daysMatch ? parseInt(daysMatch[1], 10) : 0;
    let hours = hoursMatch ? parseInt(hoursMatch[1], 10) : 0;
    let minutes = minutesMatch ? parseInt(minutesMatch[1], 10) : 0;
    return days * 1440 + hours * 60 + minutes;
  }

  private _openModalSearch(): void {
    const modal = this.modalService.open(ModalVoidSearchComponent, {
      size: 'lg',
      windowClass: 'custom-class',
    });

    modal.result.then(
      (formData) => this.redirectToHomePage(),
      () => { }
    );
  }

  private _buildGroupResponse(
    flights: any,
    key: any,
    cia: string,
    data: DefaultResponseModel
  ): void {
    if (!this.flightResponse[key]) {
      this.flightResponse[key] = [];
    }
    if (this.flightsSearchFields.type == ApiFlightType.RoundTrip) {
      if (flights.slices.length == 2) {
        this.flightResponse[key].push(flights);
        this.flightResponse[key] = this.flightResponse[key].map((_x) => {
          if (_x.airline === cia) {
            _x.ciasDiff = this.checkMultiCias(cia);
            this.isMultiCias = _x.ciasDiff;
          }
          //this._buildFilterMeta(_x, data);
          return _x;
        });
      }
    } else {
      this.flightResponse[key].push(flights);
    }
  }

  ngOnChanges(): void {
    this.flightGroupsTotal = 5;
    this.flightGroupsInitialShow = 5;
  }

  priceFilter(data: StopPriceSelect): void {
    this.filterOptions.stopQuantities = [data.stopCount];
    this.filterOptions.minimalPrice = data.price;
    this.filterOptions.maximumPrice = data.price;
    this.filterOptions.airlines = [];
    if (data.airline !== null) {
      this.filterOptions.airlines.push(data.airline);
    }
  }

  airlineFilter(airline: string): void {
    this.filterOptions.airlines = [airline];
  }

  redirectToHomePage() {
    this.modalService.dismissAll();
    this.router.navigate(['/']);
  }

  setBestPrice(stopIndex: number, price: number): void {
    if (!this.bestPricesByStops[stopIndex] || this.bestPricesByStops[stopIndex] > price) {
      this.bestPricesByStops[stopIndex] = price;
    }
  }

  private _buildFilterData(filterMeta: ApiFilterModel[]) {

    Object.values(filterMeta).forEach((meta) => {
      this.stopQuantitiesList = _.union(
        this.stopQuantitiesList,
        meta.filterData.trip_1.stopQuantities
      );

      Object.keys(meta.filterData.priceByStop).forEach((airline: string) => {
        let stopFilter = new StopPriceFilter(airline, []);
        Object.keys(meta.filterData.priceByStop[airline]).forEach((stops: string) => {
          let price: number = meta.filterData.priceByStop[airline][stops];
          let stopPriceObj = new StopQtyPrice(parseInt(stops), price);

          this.maxStopsCount =
            this.maxStopsCount < parseInt(stops) ? parseInt(stops) : this.maxStopsCount;

          stopFilter.addStopQty(stopPriceObj);
        });
      });

      this.airlines = _.uniqBy(_.union(this.airlines, meta.filterData.trip_1.airlines), 'iataCode');
      if (this.isMultiCias) {
        if (!this.airlines.find((_airline) => _airline.iataCode == 'SAME')) {
          this.airlines.push({
            name: 'Ida e volta com a mesma Cia',
            color: '',
            iataCode: 'SAME',
            logoUrl: '',
          });
        } else if (!this.airlines.find((_airline) => _airline.iataCode == 'MULTI')) {
          this.airlines.push({
            name: 'Ida e volta com Cias diferentes ',
            color: '',
            iataCode: 'MULTI',
            logoUrl: '',
          });
        }
      }
      this.airports = _.uniqBy(_.union(this.airports, Object.values(meta.airports)), 'IATACode');
      let originAirportData = JSON.parse(
        sessionStorage.getItem('airport.' + this.flightsSearchFields.slices[0].originAirport)
      );
      let destinationAirportData = JSON.parse(
        sessionStorage.getItem('airport.' + this.flightsSearchFields.slices[0].destinationAirport)
      );
      let aiportRemove = this.airports.filter(
        (item) =>
          item.city.normalize('NFD').replace(/[\u0300-\u036f]/g, '') !== originAirportData.city &&
          item.city.normalize('NFD').replace(/[\u0300-\u036f]/g, '') !== destinationAirportData.city
      );
      let filterAiport = [];
      this.airports.forEach(function (element, index, array) {
        if (aiportRemove.indexOf(element) == -1) filterAiport.push(element);
      });
      this.airports = filterAiport;
    });
    this.filterOptions.baggage = new BaggageModel();
  }

  getSliceList(flights: AvailableFlightsResponse[], flightsIndex: number) {
    // this.setSelectedFamily(flightsIndex, flights[0].slices[0].mainFamily);
    flights.forEach((flight) => {
      if (!this.airlineStopsPrices.find((stop) => stop.airline == flight.airline)) {
        this.airlineStopsPrices.push(new StopPriceFilter(flight.airline, []));
      }

      const maxStops = Math.max(...flight.slices.map((slice) => slice.segments.length - 1));

      let stopQty = new StopQtyPrice(maxStops, flight.totalPrice);
      this.airlineStopsPrices.find((stop) => stop.airline == flight.airline).addStopQty(stopQty);
      this.setBestPrice(maxStops, flight.totalPrice);

      this.minimalPrice = Math.min(this.minimalPrice, flight.totalPrice);

      this.maximumPrice = Math.max(this.maximumPrice, flight.totalPrice);

      flight.slices.forEach((_slices, indexSlices) => {
        this.getCalculationDuration(_slices, indexSlices);
        this.getCalculationTimeDeparture(_slices, indexSlices);
        this.getCalculationTimeArrival(_slices, indexSlices);
      });

      if (flight.ciasDiff) {
        if (!this.multiAirlineStopsPrices) {
          this.multiAirlineStopsPrices = new StopPriceFilter('Cias diferentes', []);
        }
        this.multiAirlineStopsPrices.addStopQty(stopQty);
      }
    });
  }

  getCalculationTimeDeparture(_slices, indexSlices) {
    const filter = this.timeDeparture[indexSlices];
    if (filter) {
      this.timeDeparture[indexSlices].minimalDate =
        filter.minimalDate > new Date(_slices.departureDate)
          ? new Date(_slices.departureDate)
          : filter.minimalDate;

      this.timeDeparture[indexSlices].minimalTime = filter.minimalDate.getHours();

      this.timeDeparture[indexSlices].minimalTimeSelected =
        this.initialFilterValues.departureTime.length > 0
          ? Math.floor(this.initialFilterValues.departureTime[indexSlices].minTime.hours())
          : filter.minimalTime;

      this.timeDeparture[indexSlices].maximumDate =
        filter.maximumDate < new Date(_slices.departureDate)
          ? new Date(_slices.departureDate)
          : filter.maximumDate;

      this.timeDeparture[indexSlices].maximumTime = filter.maximumDate.getHours();

      this.timeDeparture[indexSlices].maximumTimeSelected =
        this.initialFilterValues.departureTime.length > 0
          ? Math.floor(this.initialFilterValues.departureTime[indexSlices].maxTime.hours())
          : filter.maximumTime;
    } else {
      let timeDepartureTemp: FilterTime = {
        originAirport: _slices.origin,
        destinationAirport: _slices.destination,
        minimalDate: new Date(_slices.departureDate),
        maximumDate: new Date(_slices.departureDate),
        minimalTime: Math.floor(moment.duration(_slices.departureDate).asHours()),
        maximumTime: Math.floor(moment.duration(_slices.departureDate).asHours()),
        minimalTimeSelected: Math.floor(moment.duration(_slices.departureDate).asHours()),
        maximumTimeSelected: Math.floor(moment.duration(_slices.departureDate).asHours()),
        departureDate: new Date(_slices.departureDate),
      };

      this.timeDeparture[indexSlices] = timeDepartureTemp;
    }
  }

  getCalculationTimeArrival(_slices: SliceResponse, indexSlices: number) {
    const arrivalDateMoment = moment(_slices.arrivalDate, MOMENT_FORMATS.API_FULL_DATETIME_SECONDS);

    const maximumTime =
      arrivalDateMoment.minutes() == 0 ? arrivalDateMoment.hours() : arrivalDateMoment.hours() + 1;

    let timeArrivalTemp: FilterTime = {
      originAirport: _slices.origin,
      destinationAirport: _slices.destination,
      minimalDate: new Date(_slices.arrivalDate),
      maximumDate: new Date(_slices.arrivalDate),
      minimalTime: 0,
      maximumTime: 24,
      minimalTimeSelected: new Date(_slices.arrivalDate).getHours(),
      maximumTimeSelected: maximumTime,
      departureDate: new Date(_slices.arrivalDate),
    };
    if (this.timeArrival[indexSlices]) {
      this.timeArrival[indexSlices].minimalDate =
        this.timeArrival[indexSlices].minimalDate > new Date(_slices.arrivalDate)
          ? new Date(_slices.arrivalDate)
          : this.timeArrival[indexSlices].minimalDate;

      this.timeArrival[indexSlices].minimalTimeSelected =
        this.initialFilterValues.arrivalTime.length > 0
          ? Math.floor(this.initialFilterValues.arrivalTime[indexSlices].minTime.hours())
          : this.timeArrival[indexSlices].minimalTime;

      this.timeArrival[indexSlices].maximumDate =
        this.timeArrival[indexSlices].maximumDate < new Date(_slices.arrivalDate)
          ? new Date(_slices.arrivalDate)
          : this.timeArrival[indexSlices].maximumDate;

      this.timeArrival[indexSlices].maximumTimeSelected =
        this.initialFilterValues.arrivalTime.length > 0
          ? Math.floor(this.initialFilterValues.arrivalTime[indexSlices].maxTime.hours())
          : this.timeArrival[indexSlices].maximumTime;
    } else {
      this.timeArrival[indexSlices] = timeArrivalTemp;
    }
  }

  getCalculationDuration(_slices, indexSlices) {
    let duration = moment(_slices.arrivalDate).diff(moment(_slices.departureDate), 'hours');
    let filterDurationTemp: FilterDuration = {
      originAirport: _slices.origin,
      destinationAirport: _slices.destination,
      minimalDuration: duration,
      maximumDuration: duration,
      minimalDurationSelected: duration,
      maximumDurationSelected: duration,
    };
    if (this.filterDuration[indexSlices]) {
      this.filterDuration[indexSlices].minimalDuration =
        this.filterDuration[indexSlices].minimalDuration > duration
          ? duration
          : this.filterDuration[indexSlices].minimalDuration;

      this.filterDuration[indexSlices].minimalDurationSelected =
        this.initialFilterValues.durationTime.length > 0
          ? Math.floor(this.initialFilterValues.durationTime[indexSlices].minDuration.asHours())
          : this.filterDuration[indexSlices].minimalDuration;

      this.filterDuration[indexSlices].maximumDuration =
        this.filterDuration[indexSlices].maximumDuration < duration
          ? duration
          : this.filterDuration[indexSlices].maximumDuration;

      this.filterDuration[indexSlices].maximumDurationSelected =
        this.initialFilterValues.durationTime.length > 0
          ? Math.floor(this.initialFilterValues.durationTime[indexSlices].maxDuration.asHours())
          : this.filterDuration[indexSlices].maximumDuration;
    } else {
      this.filterDuration[indexSlices] = filterDurationTemp;
    }
  }

  getFlightsFilteredBySlices(
    flights: AvailableFlightsResponse[],
    sliceIndex: number
  ): AvailableFlightsResponse[] {
    let flightsCopies: AvailableFlightsResponse[] = [];
    flights.forEach((flight, index) => {
      let flightCopy = new AvailableFlightsResponse();
      flightCopy.airline = flight.airline;
      flightCopy.credentialName = flight.credentialName;
      flightCopy.slices = [flight.slices[sliceIndex]];
      flightsCopies.push(flightCopy);
    });

    return flightsCopies;
  }

  // saveChosenSlice(
  //   slice: SliceResponse,
  //   groupIndex: number,
  //   sliceIndex: number
  // ) {
  //   if (!this.chosenSliceList[groupIndex]) {
  //     this.chosenSliceList[groupIndex] = [];
  //   }
  //   if (!this.chosenSliceList[groupIndex][sliceIndex]) {
  //     this.chosenSliceList[groupIndex][sliceIndex] = new SliceResponse();
  //   }
  //   this.chosenSliceList[groupIndex][sliceIndex] = { ...slice };
  // }

  saveChosenFlight(flight: AvailableFlightsResponse, groupIndex: number, sliceIndex: number) {
    if (!this.selectedFlightList[groupIndex]) {
      this.selectedFlightList[groupIndex] = [];
    }
    if (!this.selectedFlightList[groupIndex][sliceIndex]) {
      this.selectedFlightList[groupIndex][sliceIndex] = null;
    }
    // this.chosenFlightList[groupIndex][sliceIndex] = { ...flight };
    this.selectedFlightList[groupIndex][sliceIndex] = flight.slices[sliceIndex].apiSliceId;
  }

  get flightTypes(): typeof ApiFlightType {
    return ApiFlightType;
  }

  getOnTotalFlightFilter(priceGroup, groupIndex): boolean {
    if (groupIndex == 0) this.flightGroupsInitialShow = this.flightGroupsTotal;
    if (groupIndex < this.flightGroupsInitialShow) {
      if (priceGroup) {
        return true;
      } else {
        this.flightGroupsInitialShow++;
        return false;
      }
    }
    return false;
  }

  checkMultiCias(cia: string): boolean {
    return cia.split('.').length > 1 ? true : false;
  }

  onScroll() {
    if (this.flightGroupsInitialShow <= this.flightList.length) {
      this.ngxLoader.startBackground();
      this.flightGroupsInitialShow += 5;
      this.flightGroupsTotal = this.flightGroupsInitialShow;
      this.ngxLoader.stopBackground();
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private checkCredential(credential, item): boolean {
    for (let x = 0; x < item.insurers_products_credentials.length; x++) {
      if (credential == item.insurers_products_credentials[x].credential) {
        return true;
      }
    }
    return false;
  }

  private _getInsurerRequiredCountries(): void {
    let proccessmentData = this.insuranceStorageService.getIsInsuranceProccessmentActive();
    if (proccessmentData.mustRedoCheck) {
      this._apiMSInsuranceService.proccessRequiredCountries(this._airportCountries).subscribe((returndata: any) => {
        return returndata.countrieRequired ? this._proccessCountriesRequired(returndata.countrieRequired, returndata.insuranceProducts) : null;
      });
    }
  }

  private treatRequiredInsurance(fligthDetailObject, insuranceProducts, countrieRequired): boolean {
    let fligthDetailObjectElement = fligthDetailObject;
    let credential = fligthDetailObjectElement.credentialName;
    let international = fligthDetailObjectElement.international;
    return ((insuranceProducts.some(item => { return item.is_aerial && item.is_national == !international && item.active && this.checkCredential(credential, item) && countrieRequired; })))

  }

  private _proccessCountriesRequired(countrieRequired, insuranceProducts): void {
    this.resultsInsurance.forEach(element => {
      if (!this._isInsuranceRequired) {
        let responseModel = element.result;
        for (let arrayOfResultsSearch = 0; arrayOfResultsSearch < Object.values(responseModel).length; arrayOfResultsSearch++) {
          if (!this._isInsuranceRequired) {
            for (let endIndexResultObjectData = 0; endIndexResultObjectData < Object.keys(Object.values(responseModel)[arrayOfResultsSearch]).length; endIndexResultObjectData++) {
              let fligthDetailObject = Object.values(Object.values(responseModel)[arrayOfResultsSearch])[endIndexResultObjectData];
              if (Array.isArray(fligthDetailObject)) {
                fligthDetailObject.forEach((element) => {
                  let fligthDetailObjectArrayElement = element;
                  this._isInsuranceRequired = this.treatRequiredInsurance(fligthDetailObjectArrayElement, insuranceProducts, countrieRequired);
                })
              } else {
                if (!this._isInsuranceRequired) {
                  this._isInsuranceRequired = this.treatRequiredInsurance(fligthDetailObject, insuranceProducts, countrieRequired);
                }
              }
            }
          }
        }
      }
    });
  }
}

