<section class="fill-card card">
  <div class="label-offer" *ngIf="offerBlockItem.label">
    <zc-label-offer [labelData]="offerBlockItem.label"></zc-label-offer>
  </div>
  <div class="image-wrapper">
    <img [src]="imageMain" class="destination-image">
  </div>
  <div class="container">
    <div class="header-text">
      <div class="product-name">{{ titleProduct }}</div>
      <div class="destination-name">{{ destination }}</div>
    </div>
    <div class="price-text">
      <div>a partir de <span class="price-info">R$ {{ offerBlockItem.offer.price | number:'1.2-2' }}</span></div>
      <div>em até <span class="price-info price-value">{{ maxInstallments }}x</span></div>
    </div>
    <button class="offer-button" (click)="redirectResult()">Gostei! Quero saber mais</button>
  </div>
</section>