import { Directive, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[zc-body-background]',
})
export class BodyBackgroundDirective implements OnInit, OnDestroy {
  private routerSubscription!: Subscription;

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.routerSubscription = this.router.events.subscribe((event) => {      
      if (event instanceof NavigationEnd) {
        this.updateBodyBackground();
      }
    });
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

  private updateBodyBackground(): void {
    const body = document.body;

    body.className = '';

    const activeRoute = this.router.routerState.root.firstChild?.snapshot.url[0]?.path || '';

    if (activeRoute === '') {
      body.classList.add('bg-home');
    }
  }
}
