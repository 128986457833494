export enum EnvironmentsEnum {
    DEVELOPMENT = "development",
    HOMOLOG = "homolog",
    HOMOLOG01 = "homolog01",
    HOMOLOG02 = "homolog02",
    HOMOLOG03 = "homolog03",
    HOMOLOG04 = "homolog04",
    HOMOLOG05 = "homolog05",
    HOMOLOG06 = "homolog06",
    HOMOLOG07 = "homolog07",
    HOMOLOG08 = "homolog08",
    HOMOLOG09 = "homolog09",
    HOMOLOG10 = "homolog10",
    HOMOLOG11 = "homolog11",
    HOMOLOG12 = "homolog12",
    PRODUCTION = "production"
}
